import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import { Alert, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Label, Input } from "reactstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import LogoFetch from "./LogoFetch";
import Select, { components } from "react-select";
import "../../css/style.css";

//^ style variables
import cssVar from "../../assets/scss/_themes-vars.module.scss";

//^ http request
import { resendLinkHandler } from "../../http/post-api";

//^ mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { registerRequest } from "../../controller/auth_controller";
import { toast } from "react-toastify";
import ResendTimer from "../pre-built/user-manage/TwoFactorSettings/components/ResendOtpTimer";
import { string } from "yup";
import { parsePhoneNumber } from "libphonenumber-js";
import { CustomButton } from "../../themes/CustomComponent";
import { getAllCountries } from "../../http/get-api";

function SuccessRegistrationDialogBox({ email }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [isTimerCompleted, setIsTimerCompleted] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const {
    isPending: resendLinkIsPending,
    isError: resendLinkIsError,
    error: resendLinkError,
    reset: resendLinkReset,
    mutate: resendLinkMutate,
  } = useMutation({
    mutationKey: ["resend-link"],
    mutationFn: resendLinkHandler,
    onSuccess: (response) => {
      if (response.toast) {
        if (response.status) {
          toast.success(response.data);
          setIsTimerCompleted(false);
        } else {
          toast.error(response.data);

          if (response.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        }
      }
      resendLinkReset();
    },
  });

  useEffect(() => {
    if (resendLinkIsError) {
      console.log(resendLinkError);
    }
  }, [resendLinkIsError, resendLinkError]);

  function onResend() {
    resendLinkMutate(email);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOtpTimer(isCompleted) {
    setIsTimerCompleted(isCompleted);
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ elevation: 1 }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title text-success"
          style={{ color: cssVar.successDark }}
        >
          Registration Successful!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            We have sent you a confirmation link to complete your registration. Please check your email inbox and click
            on the confirmation link to activate your account.
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={onResend}
            color="primary"
            startIcon={
              resendLinkIsPending ? <Spinner size={"sm"} style={{ color: "inherit", borderWidth: "2px" }} /> : ""
            }
            disabled={!isTimerCompleted || resendLinkIsPending}
          >
            {!isTimerCompleted ? (
              <ResendTimer resendContentNm="confirmation email" onOtpTimer={handleOtpTimer} />
            ) : (
              "Resend Confirmation Email"
            )}
          </CustomButton>
          <CustomButton variant="contained" onClick={() => navigate("/login")} color="success" autoFocus>
            Login
          </CustomButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);
const Register = () => {
  const [passState, setPassState] = useState(false);
  const [confirmPassState, setConfirmPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const recaptchaRef = useRef(null);
  const [g_recaptcha_response, setCaptcha] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");

  const [errorVal, setError] = useState("");
  const [successVal, setSuccessVal] = useState("");
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const params = useParams();
  const referCode = params.referCode;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const [phoneValue, setPhoneValue] = useState();

  const {
    register,
    handleSubmit,
    watch,
    // reset,
    formState: { errors },
  } = useForm();
  const password = watch("password");

  const handleFormSubmit = async (formData) => {
    if (!g_recaptcha_response) {
      setError("Please resolve the captcha");
      setSuccessVal("");
      return false;
    }

    setLoading(true);
    let rolechk = type === "business" ? "2" : type === "client" ? "3" : "";
    let res = await registerRequest(
      formData.name,
      formData.email,
      selectedCountry.id,
      formData.contactnumber,
      formData.password,
      formData.confirmPassword,
      g_recaptcha_response,
      referCode,
      rolechk
    );

    setLoading(false);
    if (res.status) {
      setSuccessVal(res.message);
      setEmailAddress(formData.email);
      setShowRegistrationModal(true);
      setError(false);
      // reset();
    } else {
      setError(res.message);
      if (res.errors) setValidationErrors(res.errors);
      else setValidationErrors([]);
    }
  };

  useEffect(() => {
    const cookieValue = Cookies.get("");
    if (cookieValue) {
      window.location = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
    }
  }, []);

  const phoneInputNumber = (value) => {
    const phonevaluestr = String(value);
    const validPhone = isValidPhoneNumber(phonevaluestr);
    if (validPhone) {
      const fixnumber = parsePhoneNumber(phonevaluestr);
      setPhoneValue(fixnumber);
    }
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    fetch("https://api.ipartydjcrm.com/api/v1/public/countries")
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
        .filter((country) => country.id === 101 || country.id === 231) // Filter for ids 101 and 102
        .map((country) => ({
          value: country.shortname,
          label: "+" + country.phonecode,
          id: country.id,
          icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
        }));
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);
  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );
  return (
    <>
      <Head title="Register" />
      {showRegistrationModal ? <SuccessRegistrationDialogBox email={emailAddress} /> : ""}
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-2 text-center w-30 mx-auto">
          <div>
            <LogoFetch />
          </div>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Register</BlockTitle>
              <BlockDes className="d-none">
                <p>Create new {process.env.REACT_APP_PROJECT_NAME} account</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {successVal && (
            <div className="mb-3">
              <Alert color="success" className="alert-icon">
                <Icon name="alert-circle" /> {successVal}
              </Alert>
            </div>
          )}
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}
                {validationErrors.length > 0 && (
                  <ul style={{ listStyle: "circle" }}>
                    {validationErrors.map((error, key) => (
                      <li key={key}>{error}</li>
                    ))}
                  </ul>
                )}
              </Alert>
            </div>
          )}

          <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Username
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  tabIndex={1}
                  {...register("name", {
                    required: "Username is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_]*$/,
                      message: "Username should be alphanumeric without special characters",
                    },
                    minLength: { value: 3, message: "Username must be at least 3 characters long" },
                    maxLength: { value: 255, message: "Username must not exceed 255 characters" },
                  })}
                  placeholder="Enter your username"
                  className="form-control-lg bg-transparent border form-control"
                />
                {errors.name && <p className="invalid">{errors.name.message}</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="email"
                  bssize="lg"
                  id="email"
                  tabIndex={2}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Enter a valid email address",
                    },
                    maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                  })}
                  className="form-control-lg bg-transparent border form-control"
                  placeholder="Enter your email address"
                />

                {errors.email && <p className="invalid">{errors.email.message}</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="contactnumber">
                  Contact Number
                </label>
              </div>
              <div className="form-control-wrap d-flex align-center">
                <Select
                  className="w-45"
                  value={selectedCountry} // Pass the selected country object
                  options={countries}
                  onChange={handleChange}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? "white" : "black",
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      display: "flex",
                      alignItems: "center",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
                <input
                  type="number"
                  bssize="lg"
                  id="contactnumber"
                  tabIndex={3}
                  {...register("contactnumber", {
                    required: "contact number is required",
                    pattern: {
                      value: /^\d{10,15}$/,
                      message: "Enter a valid Phone Number (10-15 digits)",
                    },
                  })}
                  maxLength={15} // Enforce max length at the HTML level
                  onInput={(e) => {
                    if (e.target.value.length > 15) {
                      e.target.value = e.target.value.slice(0, 15); // Trim value to 15 digits if exceeded
                    }
                  }}
                  className="form-control-lg bg-transparent border form-control"
                  placeholder="Enter your contact number"
                />
                {errors.contactnumber && <p className="invalid">{errors.contactnumber.message}</p>}
              </div>
            </div>
            <div className="form-group d-none">
              <Label htmlFor="country" className="form-label">
                Country
              </Label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <Input type="select" name="select" id="country">
                    <option value="option_select_name">United States</option>
                    <option value="option_select_name">India</option>
                  </Input>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  tabIndex={4}
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message: "Enter strong password(eg. Abc@1234)",
                    },
                    maxLength: { value: 255, message: "Password must not exceed 255 characters" },
                  })}
                  placeholder="Enter your password"
                  className={`form-control-lg bg-transparent border form-control ${
                    passState ? "is-hidden" : "is-shown"
                  }`}
                />
                {errors.password && <span className="invalid">{errors.password.message}</span>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="confirmPassword">
                  Confirm Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#confirmPassword"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setConfirmPassState(!confirmPassState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    confirmPassState ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={confirmPassState ? "text" : "password"}
                  id="confirmPassword"
                  tabIndex={5}
                  {...register("confirmPassword", {
                    required: "Confirm password is required",
                    validate: (value) => value === password || "Passwords do not match",
                  })}
                  placeholder="Enter confirm password"
                  className={`form-control-lg bg-transparent border form-control ${
                    confirmPassState ? "is-hidden" : "is-shown"
                  }`}
                />
                {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
              </div>
            </div>
            <div className="form-group">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
                onChange={setCaptcha}
                ref={recaptchaRef}
              />
            </div>
            <div className="form-group">
              <Stack>
                <CustomButton
                  variant="contained"
                  type="submit"
                  tabIndex={5}
                  color="primary"
                  size="large"
                  className="btn-block"
                  startIcon={loading ? <Spinner size="sm" style={{ borderWidth: "2px", color: "inherit" }} /> : ""}
                >
                  {"Register"}
                </CustomButton>
              </Stack>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            {" "}
            Already have an account?{" "}
            <Link to="/">
              <strong>Login</strong>
            </Link>
          </div>
          <div className="text-center pt-4 pb-3 d-none">
            <h6 className="overline-title overline-title-sap">
              <span>OR</span>
            </h6>
          </div>
          <ul className="nav justify-center gx-8 d-none">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Facebook
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Google
              </a>
            </li>
          </ul>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default Register;
